import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
function grandTotal (data) {
  let total = 0
  data.forEach((item, index) => {
    item.details.forEach((item1, index) => {
      total += item1.received_amount
    })
  })
  return total
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('fertilizerReport.godown'), style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.godown_bn : search.godown, style: 'search' },
            { text: vm.$t('fertilizerConfig.sales_center.sales_center'), style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.sale_name_bn : search.sale_name, style: 'search' },
            { text: vm.$t('fertilizerConfig.fertilizer_name'), style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fertilizer_name_bn : search.fertilizer_name, style: 'search' }
          ],
          [
            { text: vm.$t('movement.program_no'), style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.$n(search.program_no), style: 'search' },
            { text: vm.$t('globalTrans.from_date'), style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.from_date), style: 'search' },
            { text: vm.$t('globalTrans.to_date'), style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.to_date), style: 'search' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['14%', '2%', '17%', '14%', '2%', '21%', '17%', '2%', '17%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'space', alignment: 'right' })
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        newDatas.map(data => {
          pdfContent.push({ text: (i18n.locale === 'bn') ? data.text_bn : data.text_en, style: 'th', alignment: 'left' })
          const allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.fromGodowns'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.receivedDate'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.loriChalanNo'), style: 'th', alignment: 'center' },
              { text: vm.$t('movement.receivedAmount'), style: 'th', alignment: 'right' }
            ]
          ]
          let totalAmt = 0
          data.details.map((report, index) => {
            allRows.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? report.from_godown.name_bn : report.from_godown.name, style: 'td', alignment: 'center' },
              { text: dateFormat(report.receive_date), style: 'td', alignment: 'center' },
              { text: report.lori_challan_no, style: 'td', alignment: 'center' },
              { text: vm.$n(report.received_amount), style: 'td', alignment: 'right' }
            ])
            totalAmt += parseInt(report.received_amount)
          })
          allRows.push(
            [
              { text: vm.$t('fertilizerReport.total'), style: 'th', colSpan: 4, alignment: 'right', bold: true },
              {},
              {},
              {},
              { text: vm.$n(totalAmt), style: 'th', alignment: 'right', bold: true }
            ]
          )
          if (newDatas.length === (data.sl + 1)) {
            allRows.push(
              [
                { text: vm.$t('fertilizerReport.GrandTotalMTon'), style: 'th', colSpan: 4, alignment: 'right', bold: true },
                {},
                {},
                {},
                { text: vm.$n(grandTotal(newDatas)), style: 'th', alignment: 'right', bold: true }
              ]
            )
          }
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['10%', '20%', '25%', '25%', '20%'],
              body: allRows
            }
          })
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          space: {
            margin: [10, 10, 10, 10]
          },
          fertilizer: {
            margin: [10, 0, 0, -13]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
          }
        }
      pdfMake.createPdf(docDefinition, null, null, null).download('inter-received-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
